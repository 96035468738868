/**
 * titles
 * 
 */

import request from '@/utils/request'

// 
export const titlesDeleteTitleName = (data) => {
    return request({
        method: 'post',
        url: '/titles/deleteTitleName',
        data
    })
}
// 
export const titlesDelestId = (data) => {
    return request({
        method: 'post',
        url: '/titles/delestId',
        data
    })
}
// 
export const titlesSelectByNameId = (data) => {
    return request({
        method: 'post',
        url: '/titles/selectByNameId',
        data
    })
}
// 
export const titlesAddTitleConmentName = (data) => {
    return request({
        method: 'post',
        url: '/titles/addTitleConmentName',
        data
    })
}
// 
export const titlesAddTitleName = (data) => {
    return request({
        method: 'post',
        url: '/titles/addTitleName',
        data
    })
}
// 查找
export const titlesSarch = (data) => {
    return request({
        method: 'post',
        url: '/titles/sarch',
        data
    })
}
// 
export const titlesSelectTitleName = (data) => {
    return request({
        method: 'post',
        url: '/titles/selectTitleName',
        data
    })
}