<template>
  <el-card id="repository">
    <header>
      <h2 style="display: flex; align-items: center">
        知识库
        <span v-if="roleName == '企业' && showTitleText">(可在此新建问题、查看管理员、组长、或者其他员工的提问/回复，并给与回复，或者新建提问。<span
            style="color: red">知识库添加的问题，只能由管理端进行删除</span>)</span>
        <span v-if="roleName == '组长' && showTitleText">(可在此新建问题、查看管理员、坐席的提问，并给与回复。<span
            style="color: red">知识库添加的问题，只能由管理端进行删除</span>)</span>
        <span v-if="roleName == '人员' && showTitleText">(可在此新建问题、查看管理员、组长、或者其他员工的提问/回复，并给与回复，或者新建提问。<span
            style="color: red">知识库添加的问题，只能由管理端进行删除</span>)</span>
        <el-tooltip class="item" effect="dark" content="点击查看详情说明" placement="top">
          <img v-if="!showTitleText" src="@/assets/images/what.png" alt=""
            style="width: 30px; height: 30px;cursor: pointer;" @click="showTitleText = true" />
        </el-tooltip>
      </h2>
      <div class="wrap" style="margin-top: 20px">
        <div class="condition">
          <div>
            <span style="margin-right: 5px">解答状态</span>
            <el-select v-model="status" size="mini">
              <el-option v-for="item in options" :key="item.status" :label="item.table" :value="item.status">
              </el-option>
            </el-select>
          </div>
          <el-input v-model="titleName" placeholder="请输入您的问题" style="width: 500px" size="mini"></el-input><el-button
            icon="el-icon-search" type="primary" @click="handleSearch"
            style="border-top-left-radius: 0; border-bottom-left-radius: 0" size="mini">查询</el-button><el-button
            class="reset" type="primary" icon="el-icon-refresh" @click="reset()" size="mini">重置</el-button><el-button
            class="reset" type="primary" icon="el-icon-plus" @click="addProblem()" size="mini">新建问题模板</el-button>
        </div>
      </div>
    </header>
    <!-- <hr> -->
    <hr>
    <main>
      <div class="content">
        <div class="matter">
          <el-collapse v-model="activeNames" accordion @change="getanswer">
            <el-collapse-item v-for="(v, i) in problemData" :key="v.id" :name="i">
              <template #title class="reply">
                <div class="reply-title" v-if="v.titleName == null">
                  问题：无
                </div>
                <div class="reply-title" v-else>问题：{{ v.titleName }}</div>
                <div class="reply-change" @click="answerAdd(v.id)">回复</div>
                <el-popconfirm confirm-button-text="是" cancel-button-text="否" class="reply-change1" icon-color="red"
                  title="确定删除该问题吗?" v-if="roleName == '企业'" @confirm="titleDel(v.id)" @cancel="cancelEvent">
                  <template #reference>
                    <div>删除</div>
                  </template>
                </el-popconfirm>
              </template>
              <div v-for="v in answerData" :key="v.id" class="del-list">
                <div class="del-content">答案：{{ v.commentName }}</div>
                <div class="del-btn">
                  <el-popconfirm confirm-button-text="是" cancel-button-text="否" icon-color="red" title="确定删除该回复吗?"
                    v-if="roleName == '企业'" @confirm="answerDel(v.id)" @cancel="cancelEvent">
                    <template #reference>
                      <div>删除</div>
                    </template>
                  </el-popconfirm>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <!--分页  -->
        <div class="pagination">
          <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
            :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
            @current-change="handlePageChange"></el-pagination>
        </div>
      </div>
    </main>
    <!-- 新建问题模板弹出框 -->
    <el-dialog title="新建问题模板" :visible.sync="addProblemVisible" :width="'40%'">
      <el-form :model="form">
        <el-form-item label="新建问题模板" prop="massRemark">
          <el-input type="textarea" :rows="8" v-model="newProblem" placeholder="请输入您的问题"></el-input>
        </el-form-item>
      </el-form>
      <template #footer><el-button @click="addProblemVisible = false">取 消</el-button>
        <el-button @click="addProblemSubmit()" type="primary">确 定</el-button>
      </template>
    </el-dialog>
    <!-- 回复弹出框 -->
    <el-dialog title="回复" :visible.sync="replyVisible" :width="'40%'">
      <el-form :model="form">
        <el-form-item label="回复" prop="massRemark">
          <el-input type="textarea" :rows="8" v-model="commentName" placeholder="请输入您的答案"></el-input>
        </el-form-item>
      </el-form>
      <template #footer><el-button @click="replyVisible = false">取 消</el-button>
        <el-button @click="replySubmit()" type="primary">确 定</el-button>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  titlesDeleteTitleName,
  titlesDelestId,
  titlesSelectByNameId,
  titlesAddTitleConmentName,
  titlesAddTitleName,
  titlesSarch,
  titlesSelectTitleName,
} from "@/api/titles";
export default {
  data() {
    return {
      showTitleText: false,
      activeNames: ["1"],
      status: 2,
      options: [
        {
          status: 1,
          table: "未解答",
        },
        {
          status: 2,
          table: "已解答",
        },
      ],
      form: {},
      commentName: null,
      replyVisible: false,
      titlesNameId: null,
      gsId: null,
      companyId: null,
      titleName: null,
      answerData: [], // 当前列答案
      problemData: [],
      newProblem: null,
      addProblemVisible: false,
      tableData: [],
      pageTotal: 0, //总共有多少数据
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
    };
  },
  created() {
    this.roleName = localStorage.getItem("roleName");
    this.user_realname = localStorage.getItem("user_realname");
    this.getList();
  },
  inject: ["reload"],
  methods: {
    // 删除问题
    titleDel(id) {
      let params = {
        id: id,
      };
      let res = titlesDeleteTitleName(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.getList();
      });
    },
    // 取消删除
    cancelEvent() {
      Message.info("取消删除");
    },
    // 删除答案
    answerDel(id) {
      let params = {
        id: id,
      };
      let res = titlesDelestId(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.answerData = res.data.data;
        this.getList();
      });
    },
    // 获得答案
    getanswer(index) {
      this.answerData = []
      console.log("获得答案", index);
      console.log("获得答案ID", this.problemData[index].id);
      // console.log("problemData", this.problemData[index].id);

      // if (!index) {
      //   return;
      // }
      let id = this.problemData[index].id;
      let params = {
        titlesNameId: id,
      };
      let res = titlesSelectByNameId(params);
      res.then((res) => {
        // let data = res.data.data;
        this.answerData = res.data.data;
      });
    },
    // 新增答案
    answerAdd(id) {
      this.titlesNameId = id;
      this.replyVisible = true;
    },
    // 新增答案确定
    replySubmit() {
      let params = {
        titlesNameId: this.titlesNameId,
        commentName: this.commentName,
      };
      let res = titlesAddTitleConmentName(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.replyVisible = false;
        this.getList();
      });
    },
    // 新建问题模板确定
    addProblemSubmit() {
      let params = {
        titleName: this.newProblem,
      };
      let res = titlesAddTitleName(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.addProblemVisible = false;
        this.getList();
      });
    },
    // 新建问题模板
    addProblem() {
      this.addProblemVisible = true;
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    //重置刷新按钮
    reset() {
      this.titleName = null;
      this.status = null;
      this.getList();
    },
    //点击查找
    handleSearch() {
      this.page = 1;
      let params = {
        page: this.page,
        size: this.size,
        companyId: this.gsId,
        titleName: this.titleName,
        status: this.status,
      };
      let res = titlesSarch(params);
      res.then((res) => {
        let data = res.data.data;
        this.pageTotal = res.data.total;
        this.problemData = data;
      });
    },
    //展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        status: 2,
      };
      let res = titlesSelectTitleName(params);
      res.then((res) => {
        let data = res.data.data;
        this.gsId = data[0].companyId;
        this.problemData = data;
        this.pageTotal = res.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #FFF;
  background-color: #3278FE;
  border-color: #3278FE;

  &:hover {
    opacity: 0.7;
  }
}

#repository {
  height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;

      >span {
        font-size: 18px;
        color: #606369;
      }
    }

    .condition /deep/.el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .condition {
      text-align: center;
      display: flex;
      align-items: center;
    }

    .wrap {
      display: flex;
      justify-content: space-around;
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }

    .del-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .del-content {
      flex: 1;
      border-bottom: 1px solid #f2f6fc;
      line-height: 42px;
    }

    .del-btn {
      color: red;
      width: 10%;
      line-height: 42px;
      margin-left: 55px;
    }

    .reply {
      display: flex;
    }

    .reply-change1 {
      color: red;
      width: 5%;
      /* text-align: center; */
    }

    .reply-change {
      /* text-align: center; */
      color: #3278FE;
      width: 5%;
      margin-left: 5%;
    }

    .reply-title {
      flex: 1;
      font-size: 16px;
    }

    div /deep/.el-collapse-item__content {
      font-size: 14px;
      /* text-indent: 40px; */
      padding-left: 3%;
      box-sizing: border-box;
    }



    .container {
      min-width: 1200px;
    }

    header>.title {
      font-weight: normal;
    }
  }
}
</style>